<template>
  <div>
    <h1>{{ currentPage === 'home' ? 'Home' : 'Articles' }}</h1>

    <!-- Home Page Content -->
    <div v-if="currentPage === 'home'">
      <AppArticle title="Hello! Here is Zhihe Ping">
        <p>I am an Unimelb Student, Master of IT.</p>
        <p>I like astronomy, physics, and computer science.</p>
        <p>This is my blog and welcome to my own world!</p>
      </AppArticle>
      <AppArticle title="TO DO">
        <p>Learn more about machine learning and Artificial Intelligence</p>
        <p>Develop the backend part of the blog</p>
        <p>Start to learn Quantum Computing</p>
        <p>Construct the basic knowledge about Quantum Mechanics</p>
        <p>Improve my language skills</p>
      </AppArticle>
    </div>

    <!-- Articles Page Content -->
    <div v-else>
      <AppArticle
        v-for="article in articles"
        :key="article.id"
        :title="article.title"
      >
        <p>{{ article.content }}</p>
      </AppArticle>
    </div>

    <!-- Link to external page with icon -->
    <a href="https://charlesping.com/learning-notes/" target="_blank" class="icon-link">
      <i class="fas fa-book"></i> <!-- Font Awesome icon -->
      {{ currentPage === 'home' ? 'Go to Learning Notes' : 'Go to Home' }}
    </a>
  </div>
</template>

<script>
import AppArticle from './Article.vue';

export default {
  name: 'BlogPage',
  components: {
    AppArticle,
  },
  data() {
    return {
      currentPage: 'home', // 'home' or 'articles'
      articles: [
        { id: 1, title: 'Article 1', content: 'Content for article 1' },
        { id: 2, title: 'Article 2', content: 'Content for article 2' },
      ],
    };
  },
  methods: {
    togglePage() {
      this.currentPage = this.currentPage === 'home' ? 'articles' : 'home';
    },
  },
};
</script>

<style scoped>
.icon-link {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: #007bff;
  font-size: 18px;
}

.icon-link i {
  margin-right: 8px;
  font-size: 20px;
}

.icon-link:hover {
  color: #0056b3;
}
</style>