<template>
  <div class="article">
    <h2>{{ title }}</h2>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppArticle',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.article {
  margin-bottom: 20px;
}
h2 {
  font-size: 24px;
  margin-bottom: 10px;
}
.content {
  font-size: 16px;
}
</style>